import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { v4 } from 'uuid'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Content, { HTMLContent } from '../components/Content'
import headerImage from '../img/bg-edu2.jpg'
import ImageBox from '../components/ImageBox'


export const EducationPageTemplate = ({ title, intro, infographics, content, contentComponent }) => {
    const PageContent = contentComponent || Content

    return (
        <div className="page-wrap">
            <Helmet titleTemplate="%s | Mast Cell Hope">
                <title>{ title }</title>
            </Helmet>
            <PageHeader bgImage={ headerImage } titleText={title} />
            <section className="section has-background-primary has-text-white">
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <h2 className="title has-text-centered has-text-white is-uppercase is-size-3 is-size-5-mobile">{intro.heading}</h2>
                                <span className="is-size-6-mobile is-size-5">{intro.text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section-hp" style={{ backgroundColor: '#f3f3f3', paddingTop: '3rem' }}>
                <div className="container">
                    <div className="content">
                        <h2>Learning Resources</h2>
                        <div className="columns">
                            <div className="column is-4">
                                <Link className="resource-link" to="/education/overview">
                                    <svg width="50" height="50"><use href="#icon-ov" fill="currentColor" /></svg>
                                    <span className="resource-text">
                                        <span className="line1">Disease</span>
                                        <span className="line2">Overview</span>
                                    </span>
                                </Link>
                            </div>
                            <div className="column is-4">
                                <Link className="resource-link" to="/education/common-treatments">
                                    <svg width="50" height="50"><use href="#icon-tr" fill="currentColor" /></svg>
                                    <span className="resource-text">
                                        <span className="line1">Common</span>
                                        <span className="line2">Treatments</span>
                                    </span>
                                </Link>
                            </div>
                            <div className="column is-4">
                                <Link className="resource-link" to="/education/emergency-treatments">
                                    <svg width="50" height="50"><use href="#icon-em" fill="currentColor" /></svg>
                                    <span className="resource-text">
                                        <span className="line1">Emergency</span>
                                        <span className="line2">Treatment</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-4">
                                <Link className="resource-link" to="/education/triggers-and-symptoms">
                                    <svg width="50" height="50"><use href="#icon-st" fill="currentColor" /></svg>
                                    <span className="resource-text">
                                        <span className="line1">Triggers &amp;</span>
                                        <span className="line2">Symptoms</span>
                                    </span>
                                </Link>
                            </div>
                            <div className="column is-4">
                                <Link className="resource-link" to="/education/testing-and-diagnosis">
                                    <svg width="50" height="50"><use href="#icon-dt" fill="currentColor" /></svg>
                                    <span className="resource-text">
                                        <span className="line1">Testing &amp;</span>
                                        <span className="line2">Diagnosis</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section-hp">
                <div className="container">
                    <div className="content">
                        <PageContent content={content} />
                    </div>
                </div>
            </section>
            <section>
                <div className="section has-background-secondary has-text-white">
                    <div className="container">
                        <div className="content">
                            <h2 className="title has-text-centered has-text-white is-size-3 is-size-5-mobile" style={{ marginBottom: '0' }}>INFOGRAPHICS FOR PATIENTS</h2>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="columns is-multiline edu-images">
                            {infographics.images.map(image => (
                            <div key={v4()} className="column is-12-mobile is-6-tablet is-3-desktop">
                                <ImageBox pic={image} titleText={image.alt} />
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none'}}>
                <symbol id="icon-ov" viewBox="0 0 512 512">
                    <path d="M299.97,32.313C291.405,16.342,274.501,6,256,6c-18.506,0-35.41,10.342-43.97,26.313H69.159V506h373.682V32.313H299.97z
                        M233.349,84.945l-6.826-11.836c-3.14-5.449-4.731-11.206-4.731-17.109c0-4.126,0.757-8.218,2.183-11.836l0.068-0.171
                        c4.893-13.281,17.734-22.202,31.958-22.202s27.065,8.921,31.958,22.202l0.068,0.171c1.426,3.613,2.188,7.71,2.188,11.836
                        c0,5.903-1.592,11.66-4.736,17.109l-6.826,11.836h66.826c1.426,0,2.627,1.206,2.627,2.632V106H163.896V87.577
                        c0-1.426,1.201-2.632,2.632-2.632H233.349z M363.896,121.791V106h26.318v352.637H121.786V106h26.318v15.791H363.896z
                        M427.05,490.209H84.945V48.104h121.704C206.229,50.702,206,53.349,206,56c0,4.414,0.625,8.838,1.841,13.159h-41.313
                        c-10.161,0-18.423,8.262-18.423,18.418v2.632H106v384.209h299.995V90.209h-42.1v-2.632c0-10.156-8.262-18.418-18.418-18.418h-41.318
                        c1.211-4.321,1.836-8.745,1.836-13.159c0-2.651-0.225-5.298-0.645-7.896H427.05V490.209z"/>
                    <rect x="245.473" y="48.104" width="21.05" height="15.791"/>
                    <rect x="145.473" y="163.896" width="31.582" height="15.786"/>
                    <rect x="198.104" y="163.896" width="168.418" height="15.786"/>
                    <rect x="145.473" y="269.154" width="31.582" height="15.791"/>
                    <rect x="198.104" y="269.154" width="168.418" height="15.791"/>
                    <rect x="145.473" y="374.418" width="31.582" height="15.791"/>
                    <rect x="198.104" y="374.418" width="168.418" height="15.791"/>
                    <rect x="198.104" y="195.473" width="63.154" height="15.791"/>
                    <rect x="198.104" y="300.736" width="63.154" height="15.791"/>
                    <rect x="198.104" y="406" width="63.154" height="15.791"/>
                </symbol>
                <symbol id="icon-tr" viewBox="0 0 512 512">
                    <path d="M109.561,448.388c-1.7-0.002-3.329-0.678-4.53-1.881l-39.529-39.528c-2.501-2.472-2.526-6.503-0.055-9.005
                        c0.018-0.019,0.037-0.037,0.055-0.055l22.307-22.307c2.513-2.477,6.547-2.477,9.06,0l39.529,39.528c2.476,2.513,2.476,6.548,0,9.06
                        l-22.307,22.307C112.89,447.71,111.26,448.386,109.561,448.388z"/>
                    <path d="M149.73,443.942c-1.704,0.019-3.34-0.661-4.53-1.88l-75.254-75.254c-2.502-2.472-2.526-6.503-0.055-9.005
                        c0.018-0.018,0.036-0.036,0.055-0.054l216.744-216.788c2.513-2.477,6.548-2.477,9.061,0l75.295,75.297
                        c1.19,1.205,1.853,2.835,1.838,4.529c0.005,1.692-0.655,3.319-1.838,4.53L154.26,442.062
                        C153.067,443.277,151.433,443.956,149.73,443.942z M83.536,362.279l66.195,66.194l207.685-207.686l-66.194-66.194L83.536,362.279z"
                        />
                    <rect x="-5.882" y="455.43" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 412.2016 752.9417)" width="112.09" height="12.82"/>
                    <path d="M377.243,180.148c-1.702,0.013-3.337-0.665-4.529-1.88l-38.974-38.973c-2.499-2.503-2.499-6.558,0-9.061l74.485-74.527
                        c2.517-2.504,6.585-2.504,9.102,0l38.974,38.974c2.513,2.493,2.53,6.552,0.037,9.064c-0.013,0.013-0.024,0.025-0.037,0.037
                        l-74.527,74.485C380.58,179.483,378.946,180.161,377.243,180.148z M347.33,134.766l29.913,29.913l65.468-65.467l-29.913-29.914
                        L347.33,134.766z"/>
                    <path d="M481.428,135.321c-1.698-0.006-3.326-0.682-4.53-1.881l-98.329-98.329c-2.5-2.503-2.5-6.558,0-9.06L396.729,7.89
                        c2.472-2.502,6.503-2.526,9.005-0.055c0.019,0.019,0.036,0.036,0.055,0.055l98.33,98.33c1.219,1.19,1.898,2.826,1.88,4.529
                        c0.014,1.703-0.665,3.337-1.88,4.53l-18.162,18.161C484.759,134.647,483.128,135.324,481.428,135.321z"/>
                    <rect x="253.544" y="182.13" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 581.6189 148.9715)" width="12.82" height="25.645"/>
                    <rect x="219.528" y="216.134" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 547.5967 231.0815)" width="12.82" height="25.645"/>
                    <rect x="185.512" y="250.137" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 513.5693 313.2078)" width="12.82" height="25.645"/>
                    <rect x="151.496" y="284.184" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 479.5777 395.3641)" width="12.82" height="25.644"/>
                    <rect x="117.481" y="318.188" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 445.5494 477.474)" width="12.819" height="25.644"/>
                    <path d="M379.252,239.976c-1.696-0.015-3.321-0.689-4.53-1.88L273.914,137.287c-1.194-1.206-1.869-2.832-1.88-4.53
                        c0.001-1.699,0.678-3.328,1.88-4.529l28.889-28.888c2.47-2.502,6.501-2.526,9.003-0.056c0.019,0.019,0.037,0.037,0.056,0.056
                        l100.808,100.808c1.22,1.19,1.898,2.827,1.881,4.53c0.013,1.702-0.666,3.337-1.881,4.529l-28.888,28.889
                        C382.583,239.302,380.952,239.979,379.252,239.976z M287.546,132.757l91.706,91.706l19.828-19.785l-91.749-91.749L287.546,132.757z"
                        />
                </symbol>
                <symbol id="icon-em" viewBox="0 0 512 512">
                    <path d="M232.489,44.198h-86.072c-3.241,0-5.868-2.627-5.868-5.868V13.767c0-3.241,2.627-5.868,5.868-5.868h86.072
                        c3.24,0,5.867,2.627,5.867,5.868V38.33C238.356,41.571,235.729,44.198,232.489,44.198z M152.325,32.422h74.296V19.635h-74.296
                        V32.422z"/>
                    <path d="M366.959,504.101h-122.37c-33.628,0-60.983-27.356-60.983-60.983V420.86h11.735v22.257
                        c0.022,27.19,22.058,49.225,49.248,49.248h122.37c27.19-0.023,49.227-22.058,49.248-49.248V205.134h11.735v237.983
                        C427.942,476.745,400.587,504.101,366.959,504.101z"/>
                    <rect x="64.432" y="84.3" width="33.587" height="11.744"/>
                    <rect x="64.432" y="133.265" width="33.587" height="11.744"/>
                    <rect x="64.432" y="182.189" width="33.587" height="11.743"/>
                    <rect x="64.432" y="231.153" width="33.587" height="11.743"/>
                    <rect x="64.432" y="280.118" width="33.587" height="11.743"/>
                    <polygon points="198.133,196.474 198.133,179.073 180.772,179.073 180.772,196.474 163.413,196.474 163.413,213.834 
                        180.772,213.834 180.772,231.194 198.133,231.194 198.133,213.834 215.533,213.834 215.533,196.474 "/>
                    <path d="M198.214,392.655h-17.521c-69.774,0.022-126.355-56.522-126.378-126.296c0-0.027,0-0.054,0-0.081V73.132
                        c0.022-22.474,18.235-40.688,40.71-40.71h188.897c22.465,0.045,40.665,18.245,40.709,40.71v193.146
                        c0.022,69.774-56.521,126.354-126.296,126.377C198.295,392.655,198.254,392.655,198.214,392.655L198.214,392.655z M95.024,44.198
                        c-15.986,0-28.952,12.947-28.975,28.934v193.146c0.022,63.306,51.337,114.62,114.643,114.642h17.521
                        c63.306-0.022,114.619-51.336,114.642-114.642V73.132c-0.022-15.971-12.963-28.912-28.934-28.934H95.024z"/>
                    <path d="M222.615,426.728H156.29c-3.218,0.023-5.845-2.568-5.867-5.786c0-0.027,0-0.055,0-0.082v-34.073
                        c0-3.241,2.627-5.868,5.867-5.868h66.325c3.24-0.022,5.886,2.586,5.908,5.827c0,0.014,0,0.027,0,0.041v34.073
                        c0,3.241-2.627,5.868-5.867,5.868C222.642,426.728,222.629,426.728,222.615,426.728z M162.158,414.993h54.589v-22.337h-54.589
                        V414.993z"/>
                    <path d="M422.075,211.042c-14.831-0.022-26.848-12.04-26.87-26.87v-42.732c0-3.241,2.627-5.868,5.867-5.868l0,0h42.005
                        c3.24,0,5.868,2.627,5.868,5.868l0,0v42.732C448.923,199.002,436.905,211.02,422.075,211.042z M406.98,147.307v36.865
                        c0.433,8.348,7.55,14.764,15.897,14.332c7.743-0.401,13.931-6.589,14.332-14.332v-36.865H406.98z"/>
                    <rect x="386.505" y="135.571" width="71.181" height="11.743"/>
                    <rect x="416.207" y="98.302" width="11.735" height="43.141"/>
                    <path d="M189.473,266.634c-33.911,0-61.5-27.589-61.5-61.5s27.589-61.5,61.5-61.5s61.5,27.589,61.5,61.5
                        S223.384,266.634,189.473,266.634z M189.473,148.634c-31.154,0-56.5,25.346-56.5,56.5s25.346,56.5,56.5,56.5s56.5-25.346,56.5-56.5
                        S220.627,148.634,189.473,148.634z"/>
                </symbol>
                <symbol id="icon-dt" viewBox="0 0 512 512">
                <path d="M435.5,295.15c0-26.529-21.947-48.111-48.934-48.111c-26.985,0-48.933,21.582-48.933,48.111
                    c0,23.666,17.498,43.347,40.433,47.322v49.955c0,47.173-39.287,85.539-87.647,85.539c-48.281,0-87.565-38.366-87.565-85.539v-25.11
                    c8.284-3.337,14.149-11.347,14.149-20.71v-44.916c45.534-8.699,79.011-43.114,79.011-82.51c0-8.666-5.072-16.112-12.385-19.826
                    l25.035-110.725c3.32-12.688,1.162-26.334-5.894-37.444c-4.997-7.861-13.995-12.982-23.939-13.883
                    c-2.059-6.23-7.919-10.77-14.892-10.77h-0.771C258.029,21.532,250.26,19,239.983,19c-16.56,0-30.028,11.43-30.028,25.479
                    c0,14.049,13.468,25.475,30.028,25.475c6.823,0,14.41-0.647,20.411-5.001h3.545c6.865,0,12.65-4.399,14.801-10.476
                    c4.142,0.71,7.728,2.772,9.687,5.856c4.532,7.126,5.91,15.871,3.735,24.259l-25.649,113.372
                    c-9.131,2.889-15.805,11.252-15.805,21.217c0,21.296-26.334,39.977-56.354,39.977s-56.35-18.681-56.35-39.977
                    c0-9.965-6.674-18.328-15.809-21.217L96.488,84.323c-2.117-8.114-0.73-16.859,3.802-23.994c1.955-3.083,5.537-5.146,9.687-5.852
                    c2.146,6.076,7.936,10.476,14.792,10.476h3.549c5.997,4.354,13.584,5.001,20.407,5.001c16.56,0,30.032-11.426,30.032-25.475
                    C178.757,30.43,165.285,19,148.725,19c-10.272,0-18.046,2.532-23.176,7.533h-0.78c-6.964,0-12.825,4.54-14.883,10.77
                    c-9.957,0.896-18.947,6.018-23.939,13.883c-7.06,11.114-9.214,24.761-5.968,37.175l25.105,110.994
                    c-7.317,3.714-12.38,11.16-12.38,19.826c0,39.396,33.473,73.811,78.999,82.51v44.916c0,9.363,5.869,17.373,14.153,20.71v25.11
                    C185.854,448.98,232.762,495,290.502,495c57.657,0,104.564-46.02,104.564-102.572v-49.955
                    C418.002,338.497,435.5,318.816,435.5,295.15z M263.939,49.626h-9.562l-2.25,2.299c-1.851,1.897-5.483,2.702-12.144,2.702
                    c-7.985,0-14.729-4.648-14.729-10.147c0-5.504,6.744-10.152,14.729-10.152c4.798,0,11.003,0.723,13.314,4.155l2.274,3.378
                    l8.807,0.253L263.939,49.626z M124.769,41.86h8.38l2.274-3.383c2.303-3.432,8.504-4.15,13.302-4.15
                    c7.985,0,14.734,4.648,14.734,10.152c0,5.499-6.749,10.147-14.734,10.147c-6.657,0-10.293-0.805-12.14-2.702l-2.254-2.299
                    l-9.994-0.253L124.769,41.86z M109.703,219.182c0-2.947,2.536-5.342,5.653-5.342c3.113,0,5.648,2.395,5.648,5.342
                    c0,30.904,33.594,57.01,73.35,57.01c39.761,0,73.354-26.106,73.354-57.01c0-2.947,2.54-5.342,5.653-5.342
                    c3.112,0,5.652,2.395,5.652,5.342c0,32.95-30.771,61.658-71.569,66.788l-7.441,0.93v59.708c0,2.946-2.536,5.337-5.649,5.337
                    c-3.117,0-5.653-2.391-5.653-5.337v-59.708l-7.441-0.93C140.465,280.84,109.703,252.132,109.703,219.182z M386.566,326.212
                    c-17.605,0-31.933-13.929-31.933-31.062c0-17.141,14.327-31.078,31.933-31.078c17.606,0,31.934,13.938,31.934,31.078
                    C418.5,312.283,404.173,326.212,386.566,326.212z"/>
                </symbol>
                <symbol id="icon-st" viewBox="0 0 512 512">
                    <path d="M291.105,261.26c-1.591,0-3.117-0.632-4.242-1.758l-33.942-33.941c-2.343-2.343-2.343-6.142,0-8.485l109.094-109.098
                        c1.126-1.125,2.651-1.757,4.243-1.757s3.117,0.632,4.243,1.757l33.94,33.941c2.343,2.343,2.343,6.142,0,8.485L295.349,259.502
                        C294.223,260.628,292.697,261.26,291.105,261.26z M265.649,221.318l25.456,25.456l100.608-100.612l-25.455-25.456L265.649,221.318z
                        "/>
                    <path d="M47.866,494.566c-0.001,0-0.002,0-0.003,0c-8.49-0.001-16.625-3.629-22.319-9.953
                        c-10.667-11.849-9.759-30.395,2.067-42.221L128.34,341.66c7.521-7.521,13.228-16.385,16.962-26.343l21.771-58.06
                        c4.34-11.573,10.972-21.874,19.713-30.615L383.834,29.589c7.178-7.177,16.721-11.13,26.871-11.13
                        c10.149,0,19.692,3.953,26.869,11.13l45.255,45.255c14.816,14.816,14.816,38.924,0,53.741L285.782,325.637
                        c-8.737,8.739-19.037,15.372-30.614,19.715l-58.058,21.772c-9.959,3.734-18.822,9.441-26.343,16.962L69.077,485.779
                        C63.41,491.446,55.877,494.566,47.866,494.566z M410.705,30.459c-6.945,0-13.475,2.705-18.386,7.615L195.272,235.127
                        c-7.521,7.522-13.228,16.385-16.962,26.343l-21.771,58.06c-4.34,11.573-10.972,21.874-19.713,30.614L36.096,450.877
                        c-7.189,7.19-7.922,18.723-1.634,25.707c3.473,3.857,8.232,5.981,13.403,5.982c0,0,0.001,0,0.001,0
                        c4.807,0,9.325-1.872,12.725-5.272l101.69-101.692c8.741-8.741,19.041-15.374,30.614-19.714l58.058-21.772
                        c9.962-3.736,18.824-9.443,26.342-16.963L474.344,120.1c10.138-10.138,10.138-26.632,0.001-36.77L429.09,38.075
                        C424.179,33.164,417.649,30.459,410.705,30.459z"/>
                    <path d="M110.896,441.474c-1.536,0-3.071-0.586-4.243-1.758l-33.941-33.941c-2.343-2.343-2.343-6.142,0-8.485
                        c2.343-2.342,6.142-2.343,8.485,0.001l33.941,33.941c2.343,2.343,2.343,6.142,0,8.485
                        C113.967,440.888,112.431,441.474,110.896,441.474z"/>
                </symbol>
            </svg>
        </div>
    )
}

EducationPageTemplate.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
    }),
    infographics: PropTypes.shape({
        heading: PropTypes.string,
        images: PropTypes.shape({
            alt: PropTypes.string,
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
    }),
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const EducationPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <EducationPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                intro={post.frontmatter.intro}
                infographics={post.frontmatter.infographics}
                content={post.html}
            />
        </Layout>
    )
}

EducationPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default EducationPage

export const educationPageQuery = graphql`
query EducationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
            title
            intro {
                heading
                text
            }
            infographics {
                heading
                images {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 92) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    alt
                }
            }
        }
    }
}
`
