import React from "react"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const ImageBox = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            modalActiveClass: '',
        };
    }
    openModal = () => {
        this.setState({ active: true, },
            () => {
                this.setState({ modalActiveClass: 'is-active', })
            }
        )
    }
    closeModal = () => {
        this.setState({ active: false, },
            () => {
                this.setState({ modalActiveClass: '', })
            }
        )
    }
    render() {
        const { pic, titleText} = this.props;
        return(
            <div className="box" style={{ padding: '0', overflow: 'hidden' }}>
                <div className="modal-button" aria-haspopup="true" onClick={() => this.openModal()}>
                    <div className="image-box-square"  style={{ backgroundImage: `url(${pic.image.childImageSharp ? pic.image.childImageSharp.fluid.src : pic.image})` }}></div>
                    <div className="image-box-title">{titleText}</div>
                </div>
                <div className={`modal ${this.state.modalActiveClass}`}>
                    <div className="modal-background" onClick={() => this.closeModal()}></div>
                    <div className="modal-content">
                        <PreviewCompatibleImage imageInfo={pic} />
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => this.closeModal()}></button>
                </div>
            </div>
        )
    }
}

export default ImageBox